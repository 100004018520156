import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router'
import apis from '@/services/apis'
import "@/svgIcon/index.js"
Vue.use(Vant);
import { Lazyload } from 'vant';

Vue.use(Lazyload);
Vue.config.productionTip = false
Vue.prototype.$apis = apis;
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')