<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
@import "./assets/css/style.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(
    179deg,
    #6c94f6 0%,
    #436ef0 16%,
    #3a5be9 100%
  );
  height: 100vh;
  width: 100vw;
  background-size: 100%;
  overflow-y: hidden;
  position: fixed;
}
.header {
  z-index: 0;
}
h3 {
  margin-bottom: 10px;
}
.van-step__title p {
  margin-bottom: 10px !important;
}
.wrpper-title-large {
  color: #333;
  font-size: 0.3rem;
  font-weight: bold;
}
</style>

