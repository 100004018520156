import Vue from 'vue'
import cookies from "../utils/cookies";
import VueRouter from 'vue-router'
import { Toast } from 'vant';
import { format } from 'echarts';

const Login = () =>
    import ('../views/Login/index.vue')
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        meta: { auth: true, name: '首页', keepAlive: true },
        component: () =>
            import ('@/views/home/index'),
    },
    {
        path: '/login',
        name: 'Login',
        meta: { auth: false, name: '登录', keepAlive: false },
        component: Login
    },
    {
        path: '/setting',
        name: 'Setting',
        meta: { auth: false, name: '用户信息编辑', keepAlive: false },
        component: () =>
            import ('@/views/setting/index'),
    },
    {
        path: '/setting/update-password',
        name: 'Setting',
        meta: { auth: false, name: '修改密码', keepAlive: false },
        component: () =>
            import ('@/views/setting/update-password'),
    },
    {
        path: '/setting/sub-users',
        name: 'sub-users',
        meta: { auth: false, name: '子账号列表', keepAlive: false },
        component: () =>
            import ('@/views/setting/sub-users'),
    },
    {
        path: '/setting/add-subuser',
        name: 'add-subuser',
        meta: { auth: false, name: '添加子账号', keepAlive: false },
        component: () =>
            import ('@/views/setting/add-subuser'),
    },
    {
        path: '/setting/edit-subuser',
        name: 'edit-subuser',
        meta: { auth: false, name: '修改子账号', keepAlive: false },
        component: () =>
            import ('@/views/setting/edit-subuser'),
    },
    {
        path: '/setting/update-subuser-password',
        name: 'edit-subuser',
        meta: { auth: false, name: '修改子账号密码', keepAlive: false },
        component: () =>
            import ('@/views/setting/update-subuser-password'),
    },
    {
        path: '/register',
        name: 'register',
        meta: { auth: false, name: '注册', keepAlive: true },
        component: () =>
            import ('@/views/register/index'),
    },
    {
        path: '/agency',
        name: 'agency',
        meta: { auth: true, name: '旅行社管理', keepAlive: true },
        component: () =>
            import ('@/views/agency/index'),
    },
    {
        path: '/agency/item',
        name: 'agency-item',
        meta: { auth: true, name: '旅行社' },
        component: () =>
            import ('@/views/agency/item'),
    },
    {
        path: '/agency/guides',
        name: 'agency-guides',
        meta: { auth: true, name: '旅行社导游' },
        component: () =>
            import ('@/views/agency/guides'),
    },
    {
        path: '/agency/trip',
        name: 'agency-trip',
        meta: { auth: true, name: '旅行社行程' },
        component: () =>
            import ('@/views/agency/trip'),
    },
    {
        path: '/agency/tuan',
        name: 'agency-tuan',
        meta: { auth: true, name: '旅行社出团' },
        component: () =>
            import ('@/views/agency/tuan'),
    },
    {
        path: '/agency/feedbacks',
        name: 'agency-feedbacks',
        meta: { auth: true, name: '旅行社评价' },
        component: () =>
            import ('@/views/agency/feedbacks'),
    },
    {
        path: '/guider',
        name: 'guider',
        meta: { auth: true, name: '导游管理' },
        component: () =>
            import ('@/views/guider/index'),
    },
    {
        path: '/guider/item',
        name: 'guider-item',
        meta: { auth: true, name: '导游' },
        component: () =>
            import ('@/views/guider/item'),
    },
    {
        path: '/guider/feedbacks',
        name: 'guider-feedbacks',
        meta: { auth: true, name: '导游评价' },
        component: () =>
            import ('@/views/guider/feedbacks'),
    },
    {
        path: '/guider/temp_agency',
        name: 'guider-temp_agency',
        meta: { auth: true, name: '借调旅行社' },
        component: () =>
            import ('@/views/guider/temp_agency'),
    },
    {
        path: '/guider/agency',
        name: 'guider-temp_agency',
        meta: { auth: true, name: '借调旅行社详情' },
        component: () =>
            import ('@/views/guider/agency'),
    },
    {
        path: '/issue',
        name: 'issue',
        meta: { auth: true, name: '发布' },
        component: () =>
            import ('@/views/issue/index'),
    },
    {
        path: '/issue/list',
        name: 'issue',
        meta: { auth: true, name: '发布记录' },
        component: () =>
            import ('@/views/issue/list'),
    },
    {
        path: '/issue/item',
        name: 'issue',
        meta: { auth: true, name: '发布' },
        component: () =>
            import ('@/views/issue/item'),
    },
    {
        path: '/trip',
        name: 'trip',
        meta: { auth: true, name: '行程管理' },
        component: () =>
            import ('@/views/trip/index'),
    },
    {
        path: '/trip/item',
        name: 'trip-item',
        meta: { auth: true, name: '行程' },
        component: () =>
            import ('@/views/trip/item'),
    },
    {
        path: '/tuan',
        name: 'tuan',
        meta: { auth: true, name: '出团管理' },
        component: () =>
            import ('@/views/tuan/index'),
    },
    {
        path: '/tuan/item',
        name: 'tuan-item',
        meta: { auth: true, name: '出团' },
        component: () =>
            import ('@/views/tuan/item'),
    },
    {
        path: '/agreement',
        name: 'agreement',
        meta: { auth: true, name: '用户协议' },
        component: () =>
            import ('@/views/agreementPrivacy/agreement'),
    },
    {
        path: '/privacy',
        name: 'privacy',
        meta: { auth: true, name: '隐私条款' },
        component: () =>
            import ('@/views/agreementPrivacy/privacy'),
    },
    {
        path: '/emergencies',
        name: 'emergencies',
        meta: { auth: true, name: '紧急事件处理' },
        component: () =>
            import ('@/views/emergencies/index'),
    },
    {
        path: '/emergencies/item',
        name: 'emergencies-item',
        meta: { auth: true, name: '紧急事件处理' },
        component: () =>
            import ('@/views/emergencies/item'),
    },
    {
        path: '/feedbacks',
        name: 'feedbacks',
        meta: { auth: true, name: '评价' },
        component: () =>
            import ('@/views/feedbacks/index'),
    },
    {
        path: '/feedbacks/tousu',
        name: 'tousu',
        meta: { auth: true, name: '投诉' },
        component: () =>
            import ('@/views/feedbacks/tousu'),
    },
    {
        path: '/feedbacks/item',
        name: 'feedbacks-item',
        meta: { auth: true, name: '评价详情' },
        component: () =>
            import ('@/views/feedbacks/item'),
    },
    {
        path: '/dataCenter',
        name: 'dataCenter',
        meta: { auth: true, name: '数据中心' },
        component: () =>
            import ('@/views/dataCenter/index'),
    },
    {
        path: '/archive-info/item',
        name: 'archive-info-item',
        meta: { auth: true, name: '备案详情' },
        component: () =>
            import ('@/views/archive-info/item'),
    },
    {
        path: '/dailyWeekly/item',
        name: 'dailyWeekly-item',
        meta: { auth: true, name: '日报周报详情' },
        component: () =>
            import ('@/views/dailyWeekly/item'),
    },
    {
        path: '/yiqing',
        name: 'yiqing',
        meta: { auth: true, name: '疫情', keepAlive: true },
        redirect: { name: "yiqingMain" },
        component: () =>
            import ('@/views/yiqing/index'),
        children: [{
                path: 'main',
                name: 'yiqingMain',
                meta: { auth: true, name: '疫情信息' },
                component: () =>
                    import ('@/views/yiqing/main')
            },
            {
                path: 'area',
                name: 'yiqingArea',
                meta: { auth: true, name: '疫情信息' },
                component: () =>
                    import ('@/views/yiqing/area.detail')
            },
            {
                path: 'riskarea',
                name: 'yiqingRiskarea',
                meta: { auth: true, name: '风险地区' },
                component: () =>
                    import ('@/views/yiqing/risk.area')
            },
            // 星级管理
            {
                path: '/startManage',
                name: 'startManage',
                meta: { auth: true, name: '星级管理' },
                component: () =>
                    import ('@/views/startManage/index'),
            },
            {
                path: '/startManage/item',
                name: 'startManage-item',
                meta: { auth: true, name: '星级详情' },
                component: () =>
                    import ('@/views/startManage/item'),
            },

        ]
    },
]

const router = new VueRouter({
    mode: "history",
    routes
})
router.beforeEach((to, from, next) => {

    if (from.path == '/guider/item' && to.path == "/guider") {
        to.query.type = from.query.type
    }
    if (from.path == '/agency/item' && to.path == "/agency") {
        to.query.type = from.query.type
    }
    if (from.path == '/emergencies/item' && to.path == "/emergencies") {
        to.query.type = from.query.type
    }
    if (from.path == '/issue/item' && to.path == "/issue/list") {
        to.query.type = from.query.type
    }
    if (from.path == '/dailyWeekly/item' && to.path == "/issue/list") {
        to.query.type = from.query.type
    }
    if (from.path == '/feedbacks/item' && to.path == "/emergencies") {
        to.query.type = from.query.type
    }
    if (from.path == '/feedbacks/tousu' && to.path == "/emergencies") {
        to.query.type = from.query.type
    }
    if (from.path == '/archive-info/item' && to.path == "/emergencies") {
        to.query.type = from.query.type
    }
    const publicPages = ['/login', '/register', '/agreement', '/privacy']
    if (publicPages.includes(to.path)) {
        cookies.remove('token')
    }
    const authRequired = !publicPages.includes(to.path)

    const loggedIn = cookies.get('token')

    if (to.meta.auth) {
        if (authRequired && !loggedIn) {
            Toast('您还未登录，请登录!')
            return next('/login')
        }
    }
    next()
})


export default router