import request from '@/services/request'
const apis = {
    // ---星级管理相关---
  // 获取星级申请列表
  get_star_list(query){
    return request({
      url: "/company_star_lists",
      method: "get",
      params: query
    })
  },
  // 星级详情
  get_star_mes(id) {
    return request({
        url: `/company_star_lists/${id}`,
        method: "get"
    })
  },
  // 新增星级申请
  add_star_mes(data) {
    return request({
        url: `/company_star_lists`,
        method: "post",
        data: data
    })
  },
  // 修改星级申请
  edit_star_mes(id,data) {
    return request({
        url: `/company_star_lists/${id}`,
        method: "put",
        data: data
    })
  },

    //创建文旅局
    create_boss(data) {
        return request({
            url: "/bosses",
            method: "post",
            data: data
        })
    },
    //文旅局登录
    sing_in(user) {
        return request({
            url: '/bosses/login',
            method: 'post',
            data: user
        })
    },
    //发送短信验证码
    sms_codes(mobile) {
        return request({
            url: '/bosses/sms_codes',
            method: 'post',
            data: {
                mobile: mobile
            }
        })
    },
    //修改用户信息
    update_user(data) {
        return request({
            url: '/bosses/update_boss_user',
            method: 'put',
            data: data
        })
    },
    get_user_info() {
        return request({
            url: '/bosses/current_boss_user',
            method: 'get'
        })
    },
    //首页数据
    count_info() {
        return request({
            url: '/bosses/count_info',
            method: 'get'
        })
    },
    //发布
    post_notifications(data) {
        return request({
            url: "/notifications",
            method: "post",
            data: data
        })
    },
    //获取发布信息
    get_notifications(query) {
        return request({
            url: "/notifications",
            method: "get",
            params: query
        })
    },
    //阅读发布信息
    read_notification(relation_id) {
        return request({
            url: "/notifications/read_notification",
            method: "put",
            data: {
                relation_id: relation_id
            }
        })
    },
    //获取单个发布信息
    get_notification(id) {
        return request({
            url: "/notifications/" + id,
            method: "get",
            // params: {
            //     relation_id: relation_id
            // }
        })
    },
    //导游列表
    get_guides(data) {
        return request({
            url: "/guides",
            method: "get",
            params: data
        })
    },
    //获取单个导游
    get_guide(id) {
        return request({
            url: "/guides/" + id,
            method: "get"
        })
    },
    //评价列表
    get_feedbacks(query) {
        return request({
            url: "/feedbacks",
            method: "get",
            params: query
        })
    },
    reply_feedbacks(id, data) {
        return request({
            url: `/feedbacks/${id}/replies`,
            method: "post",
            data: data
        }) 
    },
    //评价详情
    get_feedback(id) {
        return request({
            url: "/feedbacks/" + id,
            method: "get"
        })
    },
    //突发事件列表
    get_emergencies(query) {
        return request({
            url: "/emergencies",
            method: "get",
            params: query
        })
    },
    //获取单个突发实事件
    get_emergency(id) {
        return request({
            url: "/emergencies/" + id,
            method: "get"
        })
    },
    //新建突发事件
    post_emergencie(data) {
        return request({
            url: "/emergencies",
            method: "post",
            data: data
        })
    },
    //处理突发事件
    put_emergencie(data) {
        return request({
            url: "/emergencies",
            method: "put",
            data: data
        })
    },
    //旅行社列表
    get_travel_companies(query) {
        return request({
            url: "/travel_companies",
            method: "get",
            params: query
        })
    },
    //修改旅行社
    put_travel_companies() {
        return request({
            url: "/travel_companies",
            method: "put"
        })
    },
    //获取单个旅行社
    get_travel_companie(id) {
        return request({
            url: "/travel_companies/" + id,
            method: "get"
        })
    },
    //出团记录列表
    get_travel_lists(query) {
        return request({
            url: "/travel_lists",
            method: "get",
            params: query
        })
    },
    //审核出团记录
    put_travel_lists(data) {
        return request({
            url: "/travel_lists",
            method: "put",
            data: data
        })
    },
    //获取单个出团记录
    get_travel_list(id) {
        return request({
            url: "/travel_lists/" + id,
            method: "get"
        })
    },
    //线路列表
    get_travel_plans(query) {
        return request({
            url: "/travel_plans",
            method: "get",
            params: query
        })
    },
    //获取单个线路
    get_travel_plan(id) {
        return request({
            url: "/travel_plans/" + id,
            method: "get"
        })
    },
    //审核线路
    put_travel_plans(data) {
        return request({
            url: "/travel_plans",
            method: "put",
            data: data
        })
    },
    //审核旅行社
    put_companies_status(data, id) {
        return request({
            url: "/travel_companies/" + id,
            method: "put",
            data: data
        })
    },
    //审核导游
    put_guides_status(data, id) {
        return request({
            url: "/guides/" + id,
            method: "put",
            data: data
        })
    },
    //获取导游评价
    get_guide_feedbacks(id, query) {
        return request({
            url: "/guides/" + id + '/feedbacks',
            method: "get",
            params: query
        })
    },
    //获取旅行社评价
    get_agency_feedbacks(id, query) {
        return request({
            url: "/travel_companies/" + id + '/feedbacks',
            method: "get",
            params: query
        })
    },

    //删除导游信息
    delete_guides(id) {
        return request({
            url: `/guides/${id}`,
            method: 'delete'
        })
    },
    //删除旅行社
    delete_travel_company(id) {
        return request({
            url: `/travel_companies/${id}`,
            method: 'delete'
        })
    },
    //删除旅游线路
    delete_travel_plan(id) {
        return request({
            url: `/travel_plans/${id}`,
            method: 'delete'
        })
    },
    //突发事件回复 POST /boss/h5/v1/emergencies/{id}/replies
    emergency_reply(id, data) {
        return request({
            url: `emergencies/${id}/replies`,
            method: 'post',
            data: data
        })
    },
    //导游图表数据statistics/guides
    statistics_guides() {
        return request({
            url: "/statistics/guides",
            method: "get"
        })
    },
    //旅行社图表数据
    statistics_travel_companies() {
        return request({
            url: "/statistics/travel_companies",
            method: "get"
        })
    },
    //行程图表数据
    statistics_travel_plans() {
        return request({
            url: "/statistics/travel_plans",
            method: "get"
        })
    },
    //出团数据statistics/travel_lists
    statistics_travel_lists() {
        return request({
            url: "/statistics/travel_lists",
            method: "get"
        })
    },
    //GET /boss/h5/v1/travel_companies/{id}/guides  获取旅行下的导游
    get_travel_companies_guides(id, query) {
        return request({
            url: `/travel_companies/${id}/guides`,
            method: "get",
            params: query
        })
    },
    //boss/h5/v1/travel_companies/:id/tem_guides 获取旅行下的临时导游 GET /boss/h5/v1/travel_companies/{id}/tmp_guides
    get_travel_companies_tem_guides(id, query) {
        return request({
            url: `/travel_companies/${id}/tmp_guides`,
            method: "get",
            params: query
        })
    },
    //获取旅行社下的行程 GET /boss/h5/v1/travel_companies/{id}/travel_plans
    get_travel_companies_travel_plans(id, query) {
        return request({
            url: `/travel_companies/${id}/travel_plans`,
            method: "get",
            params: query
        })
    },
    //获取旅行社下的出团 GET /boss/h5/v1/travel_companies/{id}/travel_lists
    get_travel_companies_travel_lists(id, query) {
        return request({
            url: `/travel_companies/${id}/travel_lists`,
            method: "get",
            params: query
        })
    },

    //bosses/sub_users子账号列表
    get_sub_users(query) {
        return request({
            url: "/bosses/sub_users",
            method: "get",
            params: query
        })
    },
    //添加子账号
    add_user(data) {
        return request({
            url: "/bosses/add_user",
            method: "post",
            data: data
        })
    },
    //DELETE /boss/h5/v1/bosses/delete_sub_user 删除子账号
    delete_sub_user(user_id) {
        return request({
            url: `/bosses/delete_sub_user`,
            method: 'delete',
            data: {
                user_id: user_id
            }
        })
    },
    //PUT /boss/h5/v1/bosses/edit_sub_user 编辑子账号
    edit_sub_user(user_id, data) {
        return request({
            url: `/bosses/edit_sub_user`,
            method: 'put',
            data: {
                user_id: user_id,
                user: data
            }
        })
    },
    //行程备案
    get_archive_infos() {
        return request({
            url: "/archive_infos",
            method: "get",
        })
    },
    //行程备案详情
    get_archive_info(id) {
        return request({
            url: `/archive_infos/${id}`,
            method: "get",
        })
    },
    //GET /agency/h5/v1/archive_infos/archive_info
    get_vistor_archive_info(travel_list_id, tourist_id) {
        return request({
            url: `/archive_infos/archive_info`,
            method: "get",
            params: {
                travel_list_id: travel_list_id,
                tourist_id: tourist_id
            }
        })
    },
    //GET /agency/h5/v1/reports 日报周报
    get_reports(query) {
        return request({
            url: `reports`,
            method: 'get',
            params: query
        })
    },
    //获取到日报周报详情
    get_report(id) {
        return request({
            url: `reports/${id}`,
            method: 'get'
        })
    },
    //获取借调旅行社
    get_tem_companies(guider_id, query) {
        return request({
            url: `guides/${guider_id}/tem_companies`,
            method: 'get',
            params: query
        })

    },
    yiqing_info() {
        return request({
          url: "/ncov/ncov_info ",
          method: 'get'
        })
      }

}

export default apis