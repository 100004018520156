import axios from 'axios' // 引入axios
// import qs from 'qs'
import { Toast } from 'vant';
import cookies from "../utils/cookies";
import { baseURL } from './config'
// 创建axios实例
const service = axios.create({ // 对axios进行基础匹配
    baseURL: baseURL,
    timeout: 500000,
    withCredentials: false,
    validateStatus: function(status) {

        if (status == 401) {
            Toast.fail('登录过期');
            window.location.href = "/login"
        }
        return status >= 200 && status < 300 // 默认的
    }
})

// 设置请求拦截器
service.interceptors.request.use(config => {
        const token = cookies.get('token') ? cookies.get('token') : ''
        config.headers = {
            'Token': token,
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Content-Type': 'application/json,charset=utf-8',
            'crossDomain': false
        }
        return config
    }, error => {
        Toast.fail('请求失败!')
        return Promise.reject(error)
    })
    // 设置响应拦截器
service.interceptors.response.use(data => {
    if (data.data === null) {
        return null;
    }
    if (data) {
        if (data.data.status != 200) {
            Toast(data.data.message)

        }
        return data.data
    } else {

    }

}, error => {
    Toast(error + '')
    return Promise.reject(error)
})

export default service